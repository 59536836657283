import {MapControls, OrbitControls, shaderMaterial, Sparkles, Stars} from "@react-three/drei";
import * as THREE from 'three';
import {useFrame} from "@react-three/fiber";
import EarthObject from "./scene/EarthObject";
import NetworkSphereObject from "./scene/NetworkSphereObject";

export default function Experience()
{
    useFrame((state, delta, frame) => {

    })

    let targetPosition = new THREE.Vector3(2,2,2);

    return <>

        <color args={['#1A1A2A']} attach="background" />

        <OrbitControls makeDefault />
        {/*<MapControls makeDefault />*/}

        <directionalLight intensity={3.2} position={[2,2,2]} />

        <EarthObject position={[0,0,0]} />
        <NetworkSphereObject position={[0,0,0]} />

        <Sparkles
            size={2}
            scale={[128, 128, 128]}
            position-y={0}
            speed={0.75}
            count={3000}
            color={'#1FB04B'}
        />

        <Stars radius={80} depth={20} count={10000} factor={6} saturation={0} fade speed={1} />

        </>;
}
