import {Stack, Typography} from "@mui/material";

export default function Overlay()
{
    return <div className="overlay">
        <Stack direction="row" padding={2} spacing={1}>
            <img src="/images/logo_name_slogan_white.png" height={32}/>
        </Stack>
    </div>
}
