import {useFrame, useLoader} from "@react-three/fiber";
import {useEffect, useRef} from "react";
import {AdditiveBlending, DoubleSide, DynamicDrawUsage, NormalBlending, TextureLoader, Vector3} from "three";

export default function NetworkSphereObject({
    position
                                    })
{
    const particlesCount = 3000;
    const particlesSize = 3.5;
    const maxConnections = 10;
    const maxDistance = 0.5;
    const velocityY = 0.17;
    const velocityZ = 0.12;
    const maxLineCount = particlesCount * maxConnections;

    const pointCloudRef = useRef();
    const pointGeometryRef = useRef();
    const lineGeometryRef = useRef();

    const particleData = [];
    const basePositions = [];
    const radian=Math.PI/180;
    const particlePositions = new Float32Array(particlesCount * 3);

    const linePositions = new Float32Array(particlesCount * maxConnections * 6 + 1);

    //Initialize the velocity for all particles
    for (let i = 0; i < particlesCount; i++)
    {
        particleData.push({
            velocity: new Vector3((0.5 - Math.random()) * 2.0, (0.5 - Math.random()) *2.0, (0.5 - Math.random()) * 2.0),
            connections: 0
        })
    }

    //Set the base position for each particle
    let tempVector = new Vector3();
    for (let i = 0; i < particlesCount; i++)
    {
        const particleSpeed = particleData[i];

        tempVector.set(0.5 - Math.random(), 0.5 - Math.random(), 0.5 - Math.random());
        tempVector.add(particleSpeed.velocity);
        tempVector.normalize();
        tempVector.multiplyScalar(1.5);

        const x = tempVector.x * 50;
        const y = tempVector.y * 50;
        const z = tempVector.z * 50;

        const i3 = i * 3;
        basePositions[i3] = x;
        basePositions[i3 + 1] = y;
        basePositions[i3 + 2] = z;

        particlePositions[i3] = x;
        particlePositions[i3 + 1] = y;
        particlePositions[i3 + 2] = z;
    }

    useEffect(() => {

    }, [])

    useFrame((state, delta, frame) => {

        let lineCount = 0;
        for (let p = 0; p < particlesCount; p++)
        {
            const p3 = p * 3;

            const particleSpeed = particleData[p];
            basePositions[p3 + 1] = basePositions[p3+1] + particleSpeed.velocity.y * velocityY;
            basePositions[p3 + 2] = basePositions[p3+2] + particleSpeed.velocity.z * velocityZ;

            const angle_y = basePositions[p3 + 1];
            const angle_z = basePositions[p3 + 2];

            particlePositions[p3] = (Math.cos((-angle_y - 90) * radian) * (Math.sin(angle_z * radian))) * 3.25;
            particlePositions[p3 + 1] = (Math.cos((angle_z * radian))) * 3.25;
            particlePositions[p3 + 2] = (Math.sin((-angle_y - 90)*radian)*(Math.sin(angle_z*radian))) * 3.25;

            let connCount = 0;
            for (let j = 0; j < particlesCount; j++)
            {
                if (connCount <= maxConnections && p !== j)
                {
                    const j3 = j * 3;

                    const distX = particlePositions[p3] - particlePositions[j3];
                    const distY = particlePositions[p3 + 1] - particlePositions[j3 + 1];
                    const distZ = particlePositions[p3 + 2] - particlePositions[j3 + 2];

                    const distance = Math.sqrt((distX * distX) + (distY * distY) + (distZ * distZ));
                    if (distance <= maxDistance)
                    {
                        connCount++;

                        linePositions[lineCount++] = particlePositions[p3];
                        linePositions[lineCount++] = particlePositions[p3 + 1];
                        linePositions[lineCount++] = particlePositions[p3 + 2];

                        linePositions[lineCount++] = particlePositions[j3];
                        linePositions[lineCount++] = particlePositions[j3 + 1];
                        linePositions[lineCount++] = particlePositions[j3 + 2];
                    }
                }
            }
        }
        pointGeometryRef.current.attributes.position.needsUpdate = true;
        lineGeometryRef.current.attributes.position.needsUpdate = true;
    })

    return <group ref={pointCloudRef}
                  position={position}>

        <points>
            <bufferGeometry ref={pointGeometryRef}>
                <bufferAttribute
                    attach='attributes-position'
                    count={particlesCount}
                    itemSize={3}
                    usage={DynamicDrawUsage}
                    array={particlePositions}
                />
            </bufferGeometry>
            <pointsMaterial
                size={particlesSize}
                side={DoubleSide}
                // color={0xABABAB}
                color={0x38ff82}
                blending={NormalBlending}
                sizeAttenuation={false}
                transparent={true}
            />
        </points>

        <lineSegments>
            <bufferGeometry ref={lineGeometryRef}>
                <bufferAttribute
                    attach='attributes-position'
                    count={maxLineCount}
                    itemSize={3}
                    array={linePositions}
                />
            </bufferGeometry>
            <lineBasicMaterial
                vertexColors={false}
                side={DoubleSide}
                blending={NormalBlending}
                transparent={true}
                // color={0xABABAB}
                color={0x38ff82}
            />
        </lineSegments>

    </group>
}
