import {useFrame, useLoader} from "@react-three/fiber";
import {useEffect, useRef} from "react";
import {AdditiveBlending, NormalBlending, TextureLoader} from "three";

export default function EarthObject({
    position
                                    })
{
    const earthRef = useRef();

    useEffect(() => {

    }, [])

    useFrame((state, delta, frame) => {
        earthRef.current.rotation.y += 0.001;
    })

    const earthMap = useLoader(TextureLoader, '/textures/earth/8k_earth_daymap.jpg');
    const lightsMap = useLoader(TextureLoader, '/textures/earth/4k_earth_nightmap.jpg');
    const earthSpecMap = useLoader(TextureLoader, '/textures/earth/8081_earthspec4k.jpg');
    const earthBumpMap = useLoader(TextureLoader, '/textures/earth/8081_earthbump4k.jpg');

    return <group ref={earthRef}
                  position={position}>

        <mesh>
            <sphereGeometry args={[3,32,64]} />
            <meshPhongMaterial map={earthMap} specularMap={earthSpecMap} bumpMap={earthBumpMap} bumpScale={0.1} wireframe={false} />
        </mesh>

        <mesh>
            <sphereGeometry args={[3,32,64]} />
            <meshBasicMaterial map={lightsMap} blending={AdditiveBlending} wireframe={false} />
        </mesh>

        <mesh scale={1.02}>
            <sphereGeometry args={[3,32,64]} />
            <meshBasicMaterial transparency={true} opacity={0.08} blending={AdditiveBlending} wireframe={false} />
        </mesh>

    </group>
}
